import axios from 'axios';
import toasterNotification from '../components/common/toasterNotification';
import settings from '../constants/constants';
import { checkValIsEmpty } from '../utils/common';

const getUser = () => {
  let user = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : sessionStorage.getItem('currentUser');
  return JSON.parse(user);
};

axios.defaults.headers.common['Authorization'] = `Bearer ${getUser()?.token}`;

const setUser = (userObj) => {
  userObj = JSON.stringify(userObj);
  if (localStorage.getItem('currentUser'))
    localStorage.setItem('currentUser', userObj);
  else
    sessionStorage.setItem('currentUser', userObj);
};

const logout = async (expired = false) => {
  // remove user from local storage
  const user = await getUser()?.user;
  localStorage.removeItem('currentUser');
  sessionStorage.removeItem('currentUser');
  if (user) {
    const userType = Object.keys(settings.role).find(key => settings.role[key] === user.role_id);
    window.location.href = expired ? `/login/${userType}?expired=true` : `/login/${userType}`;
  } else {
    window.location.href = '/login';
  }
};

const login = async (email, password, remember, loginType, tokenId) => {
  let requestOptions;
  if (tokenId) {
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tokenId, loginType: settings.role[loginType] })
    };
  }

  if (email && password && !tokenId) {
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password, loginType: settings.role[loginType] })
    };
  }
  const response = await fetch(`${settings.url}/user/login`, requestOptions);
  const data = await response.json();
  if (!response.ok) {
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  if (remember) {
    localStorage.setItem('currentUser', JSON.stringify(data));
  } else {
    sessionStorage.setItem('currentUser', JSON.stringify(data));
  }
  axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
  return data;
};

const isLoggedIn = () => {
  return localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : sessionStorage.getItem('currentUser');
};

const registerPharmacy = async (
  pharmacistFirstName,
  pharmacistLastName,
  pharmacistEmail,
  pharmacistAhpraNumber,
  pharmacistPassword,
  pharmacyName,
  pharmacyABN,
  pharmacyPhone,
  pharmacyCity,
  pharmacyPostalCode,
  pharmacyState,
  pharmacyCountry,
  ahpranFile,
  pharmacyAddress1,
  pharmacyAddress2
) => {
  let user=JSON.stringify({
    firstName: pharmacistFirstName,
    lastName: pharmacistLastName,
    email: pharmacistEmail,
    password: pharmacistPassword,
    ahpraNumber: pharmacistAhpraNumber,
  });
  let pharmacy = JSON.stringify({
    ahpra_no: pharmacyABN,
    name: pharmacyName,
    phone: pharmacyPhone,
    abn: pharmacyABN,
    city: pharmacyCity,
    state_id: pharmacyState,
    country_id: pharmacyCountry,
    zip_code: pharmacyPostalCode,
    address1: pharmacyAddress1,
    address2: pharmacyAddress2
  });

  let formData = new FormData(); {
    formData.append('file', ahpranFile);
    formData.append('user', user);
    formData.append('pharmacy', pharmacy);
  }
  return axios.post(`${settings.url}/user`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(async (user) => {
    if (user.status !== 200) {
      toasterNotification(`${user.message}`, 'error', { id:'user-register-failed' });
      return;
    }
    return user;
  })
    .catch(err => {
      toasterNotification(err.response.data.message, 'error', { id:'user-register-failed' });
    });
};

const registerPartnerPharmacy = async (
  pharmacistFirstName,
  pharmacistLastName,
  pharmacistEmail,
  pharmacistAhpraNumber,
  pharmacistPassword,
  pharmacyName,
  pharmacyABN,
  pharmacyPhone,
  pharmacyCity,
  pharmacyPostalCode,
  pharmacyState,
  pharmacyCountry,
  ahpranFile,
  pharmacyAddress1,
  pharmacyAddress2,
  circuitPharmacyCode,
) => {
  let user=JSON.stringify({
    firstName: pharmacistFirstName,
    lastName: pharmacistLastName,
    email: pharmacistEmail,
    password: pharmacistPassword,
    ahpraNumber: pharmacistAhpraNumber,
  });
  let pharmacy = JSON.stringify({
    ahpra_no: pharmacyABN,
    name: pharmacyName,
    phone: pharmacyPhone,
    abn: pharmacyABN,
    city: pharmacyCity,
    state_id: pharmacyState,
    country_id: pharmacyCountry,
    zip_code: pharmacyPostalCode,
    address1: pharmacyAddress1,
    address2: pharmacyAddress2,
    circuit_pharmacy_code: circuitPharmacyCode
  });

  let formData = new FormData(); {
    formData.append('file', ahpranFile);
    formData.append('user', user);
    formData.append('pharmacy', pharmacy);
  }
  return axios.post(`${settings.url}/user/partner-pharmacy`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(async (user) => {
    if (user.status !== 200) {
      toasterNotification(`${user.message}`, 'error', { id:'user-register-failed' });
      return;
    }
    return user;
  })
    .catch(err => {
      toasterNotification(err.response.data.message, 'error', { id:'user-register-failed' });
    });
};

const forgotPassword = async (email, ccEmail) => {
  return await axios.post(`${settings.url}/user/forgot-password/`, { email, ccEmail });
};

const resetPassword = async (email, password) => {
  return await axios.post(`${settings.url}/user/reset-password/`, { email, password });
};

const checkResetPasswordValidity = async (email) => {
  return await axios.get(`${settings.url}/user/reset-password/${email}`);
};

const isAdmin = () => {
  if (getUser()){
    return getUser().user.role_id === settings.role.admin;
  } else {
    toasterNotification('Please Login to continue', 'warning', { id: 'login-first' });
    return window.location.href='/login/pharmacist';
  }
};

const isPharmacist = () => {
  return getUser() && getUser().user.role_id === settings.role.pharmacist;
};

const isSupplier = () => {
  if (getUser().user){
    return getUser().user.role_id === settings.role.supplier;
  } else {
    toasterNotification('Please Login to continue', 'warning', { id: 'login-first' });
    return window.location.href='/login/pharmacist';
  }
};

const isInternalPharmacist = () => {
  return getUser()?.user.is_internal;
};

const updateUserStatus = (id) => {
  return axios.post(`${settings.url}/user/status/${id}`);
};

const getUserInfo= ()=> {
  let initials='';
  let userName= isInternalPharmacist() ? 'Circuit Pharmacy - ' : '';
  const user = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : sessionStorage.getItem('currentUser');
  const userInfo = JSON.parse(user).user;
  userName += !checkValIsEmpty(userInfo) && userInfo.first_name +' '+ userInfo.last_name;
  const voucherDetail = !checkValIsEmpty(userInfo.voucher?.amount) && { amount: userInfo.voucher.amount, status: userInfo.voucher.status.toLowerCase() === 'active' ? 'Not Redeemed' : 'Redeemed', updatedDate: userInfo.voucher.updatedDate };
  if (!checkValIsEmpty(userInfo)){
    initials = userInfo.first_name.substring(0, 1).toUpperCase();
    initials += userInfo.last_name.substring(0, 1).toUpperCase();
  }
  return {
    userName,
    initials,
    voucherDetail
  };
};

const getLoggedInUserDetails = async () => {
  const response = await axios.post(`${settings.url}/user/user-details`);
  if (response.status === 401) return null;
  return response.data;
};


export {
  login,
  logout,
  registerPharmacy,
  registerPartnerPharmacy,
  forgotPassword,
  checkResetPasswordValidity,
  resetPassword,
  isLoggedIn,
  getUser,
  setUser,
  isAdmin,
  isPharmacist,
  updateUserStatus,
  isInternalPharmacist,
  isSupplier,
  getUserInfo,
  getLoggedInUserDetails
};
